<template>
    <el-select v-model="model" filterable reserve-keyword :loading="loading" style="width: 220px"
               :filter-method="loadList" placeholder="请选择企业" @change="change">
        <el-option v-for="item in list" :key="item.compCode" :value="item.compCode" :label="item.compName"></el-option>
    </el-select>
</template>

<script>
    export default {
        data() {
            return {
                model: '',
                loading: false,
                list: [],
                loaded: false,
            }
        },
        created() {
            this.loadList()
        },
        methods: {
            loadList(keyword){
                this.loading = true
                this.$api.get('report/sysUserReport/queryAimBaseCompListByKeyword', {keyword})
                    .then(res => {
                        this.list = res.data || []
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            change() {
                this.$emit('change', {
                    compCode: this.model
                })
            },
        }
    }
</script>