<template>
    <div>
        <!-- 药品 -->
        <el-select @change="selectDrug" v-model="drugCorp.drugModel" filterable reserve-keyword
                   :loading="drugCorp.drugLoading" :filter-method="loadDrug" placeholder="请选择药品">
            <el-option v-for="item in drugCorp.drugList" :key="item.comCode" :value="item.comCode" :label="item.comName"></el-option>
        </el-select>
        <!-- 公司 -->
        <el-select v-model="drugCorp.corpModel" filterable reserve-keyword :loading="drugCorp.corpLoading"
                   :filter-method="loadDrugCorp" placeholder="请选择企业" style="margin-left: 20px;width: 220px" @change="selectCorp">
            <el-option v-for="item in drugCorp.corpList" :key="item.compCode" :value="item.compCode" :label="item.compName"></el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                drugCorp: {
                    drugModel: '',
                    corpModel: '',
                    drugLoading: false,
                    corpLoading: false,
                    drugList: [],
                    corpList: []
                },
            }
        },
        created() {
            this.loadDrug()
        },
        methods: {
            /* 药品 */
            loadDrug(keyword){
                this.drugCorp.drugLoading = true
                this.$api.get('report/sysUserReport/queryAimBaseDrugsListByKeyword', {keyword})
                    .then(res => {
                        this.drugCorp.drugList = res.data || []
                    })
                    .finally(() => {
                        this.drugCorp.drugLoading = false
                    })
            },
            selectDrug() {
                this.drugCorp.corpList = []
                this.drugCorp.corpModel = ''
                this.loadDrugCorp()
            },
            /* 药瓶 -> 企业 */
            loadDrugCorp(keyword){
                if(!this.drugCorp.drugModel){
                    this.$store.commit('error', '请先选择药品')
                    return
                }
                this.drugCorp.corpLoading = true
                this.$api.get('report/sysUserReport/queryAimBaseCompListByComCodeAndKeyword', {keyword, comCode: this.drugCorp.drugModel})
                    .then(res => {
                        this.drugCorp.corpList = res.data || []
                    })
                    .finally(() => {
                        this.drugCorp.corpLoading = false
                    })
            },
            selectCorp() {
                this.$emit('change', {
                    comCode: this.drugCorp.drugModel,
                    compCode: this.drugCorp.corpModel,
                })
            },
        }
    }
</script>